import React from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { AppContext } from "../context/AppProvider";
import { PortCentralServer } from "../lib/domain/Ports/PortCentralServer";
import { CurrentTournamentEnrollmentResponseDto } from "../lib/drivers/dto/CurrentTournamentEnrollmentResponseDto";
import { CurrentTournamentUserInformationDto } from "../lib/drivers/dto/CurrentTournamentUserInformationDto";
import LoginButton from './bonexMarginLoginBtn';
import Spinners from "./Spinners";

export type HomeMyInformationProps = {};

type HomeMyInformationState = {
  enrollmentModalOpen: boolean;
  enrollmentSubmitting: boolean;
  enrollmentMessage: string | null;
  enrollmentAgree: boolean;

  isLoading: boolean;
  data: CurrentTournamentUserInformationDto;
};

export class HomeMyInformation extends React.Component<HomeMyInformationProps, HomeMyInformationState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: HomeMyInformationState = {
    enrollmentModalOpen: false,
    enrollmentSubmitting: false,
    enrollmentMessage: null,
    enrollmentAgree: true,

    isLoading: true,
    data: null
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    try {
      const data = await PortCentralServer.Repo.client.tournamentMyInformation();

      if (data) {
        await this.setState((prevState) => {
          return {
            data: data,
            isLoading: false
          };
        });
      }
    } catch (error) {
      await this.setState((prevState) => {
        return {
          isLoading: false
        };
      });
    }
  };

  componentDidUpdate = async (prevProps: HomeMyInformationProps, prevState: HomeMyInformationState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  onEnrollmentAgreeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await this.setState((prevState) => {
      return {
        enrollmentAgree: e.target.checked
      };
    });
  };

  onTournamentEnrollmentClick = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    await this.setState((prevState) => {
      return {
        enrollmentModalOpen: true,
        enrollmentSubmitting: false,
        enrollmentMessage: null,
        enrollmentAgree: true
      };
    });
  };

  handlerTournamentEnrollmentSubmitClick = async (e: React.FormEvent<HTMLButtonElement>) => {
    await this.setState((prevState) => {
      return { enrollmentSubmitting: true };
    });

    try {
      const result: CurrentTournamentEnrollmentResponseDto = await PortCentralServer.Repo.client.tournamentEnroll();

      if (result && result.message) {
        await this.setState((prevState) => {
          return { enrollmentSubmitting: false, enrollmentMessage: result.message };
        });
      }
    } catch (error) {}

    // let success: boolean = true;

    // if (success) {
    //   await this.setState((prevState) => {
    //     return { enrollmentModalOpen: false };
    //   });
    // }
  };

  handlerTournamentEnrollmentCloseClick = async (e: React.FormEvent<HTMLButtonElement>) => {
    await this.setState((prevState) => {
      return { enrollmentModalOpen: false };
    });
  };
  // #endregion

  // #region State Setters
  setLoading = async (loading: boolean) => {
    await this.setState((prevState) => {
      return {
        isLoading: loading
      };
    });
  };
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <section id="tournament-my-information">
          <div className="overlay pt-60 pb-60">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="section-header">
                    <h2 className="title">My Information</h2>
                  </div>
                </div>
              </div>

              {this.state.isLoading ? (
                <Spinners setLoading={this.setLoading} />
              ) : (
                <>
                  <div className="row">
                    <div className="col">
                      <div className="my-info">
                        <div className="row">
                          <div className="col">
                            <h6>Rank</h6>
                            <h4>{this.state.data && this.state.data.rank > 0 ? this.state.data.rank : "--"}</h4>
                          </div>
                          <div className="col">
                            <h6>Volume</h6>
                            <h4>{this.state.data && this.state.data.tradingVolume > 0 ? this.state.data.tradingVolume.toFixed(2) : "--"} USDT</h4>
                          </div>
                          <div className="col">
                            <h6>ROI</h6>
                            <h4 className="plus">{this.state.data && this.state.data.rank > 0 ? this.state.data.points.toFixed(2) : "--"}</h4>
                          </div>
                          <div className="col">
                            <h6>Status</h6>
                            <h4>{this.state.data && this.state.data.enrollmentStatus ? this.state.data.enrollmentStatus : "--"}</h4>
                          </div>
                        </div>
                      </div>

                      {this.state.data && this.state.data.allowEnrollYn && !this.state.data.enrollmentStatus && (
                        <div className="row mt-30">
                          <div className="col text-center">
                            <button type="button" className="cmn-btn" onClick={this.onTournamentEnrollmentClick}>
                              Enter Tournament
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
        <Modal isOpen={this.state.enrollmentModalOpen} toggle={this.handlerTournamentEnrollmentCloseClick} centered={true} unmountOnClose={true} className="register">
          <ModalHeader toggle={this.handlerTournamentEnrollmentCloseClick}>
            <button type="button" className="close" onClick={this.handlerTournamentEnrollmentCloseClick}>
              <img src="images/cross-icon-1.png" alt="image" />
            </button>
          </ModalHeader>
          <ModalBody className="py-3 px-5">
            {this.context.isExchangeTokenSet() && (
              <>
                <h5>Get started in just few seconds!</h5>
                <div className="form-area">
                  {!this.state.enrollmentSubmitting && !this.state.enrollmentMessage && (
                    <form action="#">
                      <div className="form-group d-flex">
                        <div className="checkbox_wrapper">
                          <input type="checkbox" onChange={this.onEnrollmentAgreeChange} checked={this.state.enrollmentAgree} />
                          {/* <label></label> */}
                        </div>
                        <span className="check_span">
                          I agree with{" "}
                          <Link to="/terms" target="_blank">
                            <span>user agreement</span>
                          </Link>
                          , and confirm that I am at least 18 years old!
                        </span>
                      </div>
                      <div className="form-group">
                        <button type="submit" className="cmn-btn" onClick={this.handlerTournamentEnrollmentSubmitClick} disabled={!this.state.enrollmentAgree}>
                          Enroll
                        </button>
                      </div>
                    </form>
                  )}

                  {this.state.enrollmentSubmitting && (
                    <div className="text-center">
                      <div className="spinner-border text-primary" />
                    </div>
                  )}

                  {this.state.enrollmentMessage && <div className="res_info">{this.state.enrollmentMessage}</div>}
                </div>
              </>
            )}

            {!this.context.isExchangeTokenSet() && <h5 className="">Please sign in to <a className="exchange-link" href="https://www.bonex.net">BoneX Margin</a><br />to continue!<LoginButton /></h5>}
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}


export class HomeEnterTournament extends React.Component<HomeMyInformationProps, HomeMyInformationState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: HomeMyInformationState = {
    enrollmentModalOpen: false,
    enrollmentSubmitting: false,
    enrollmentMessage: null,
    enrollmentAgree: true,

    isLoading: true,
    data: null
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    try {
      const data = await PortCentralServer.Repo.client.tournamentMyInformation();

      if (data) {
        await this.setState((prevState) => {
          return {
            data: data,
            isLoading: false
          };
        });
      }
    } catch (error) {
      await this.setState((prevState) => {
        return {
          isLoading: false
        };
      });
    }
  };

  componentDidUpdate = async (prevProps: HomeMyInformationProps, prevState: HomeMyInformationState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  onEnrollmentAgreeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await this.setState((prevState) => {
      return {
        enrollmentAgree: e.target.checked
      };
    });
  };

  onTournamentEnrollmentClick = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    await this.setState((prevState) => {
      return {
        enrollmentModalOpen: true,
        enrollmentSubmitting: false,
        enrollmentMessage: null,
        enrollmentAgree: true
      };
    });
  };

  handlerTournamentEnrollmentSubmitClick = async (e: React.FormEvent<HTMLButtonElement>) => {
    await this.setState((prevState) => {
      return { enrollmentSubmitting: true };
    });

    try {
      const result: CurrentTournamentEnrollmentResponseDto = await PortCentralServer.Repo.client.tournamentEnroll();

      if (result && result.message) {
        await this.setState((prevState) => {
          return { enrollmentSubmitting: false, enrollmentMessage: result.message };
        });
      }
    } catch (error) {}

    // let success: boolean = true;

    // if (success) {
    //   await this.setState((prevState) => {
    //     return { enrollmentModalOpen: false };
    //   });
    // }
  };

  handlerTournamentEnrollmentCloseClick = async (e: React.FormEvent<HTMLButtonElement>) => {
    await this.setState((prevState) => {
      return { enrollmentModalOpen: false };
    });
  };
  // #endregion

  // #region State Setters
  setLoading = async (loading: boolean) => {
    await this.setState((prevState) => {
      return {
        isLoading: loading
      };
    });
  };
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
              {this.state.isLoading ? (
                <Spinners setLoading={this.setLoading} />
              ) : (
                <> 
                  {this.state.data && this.state.data.allowEnrollYn && !this.state.data.enrollmentStatus && (
                         <div className="countdown-button" style={{ flex: '1 1 0%', display: 'flex', justifyContent: 'center' }}>
                            <button type="button" className="cmn-btn TournamentEnrollment" onClick={this.onTournamentEnrollmentClick}>
                              Enter Tournament
                            </button> </div>
                  )}
                </>
              )}  
 
        <Modal isOpen={this.state.enrollmentModalOpen} toggle={this.handlerTournamentEnrollmentCloseClick} centered={true} unmountOnClose={true} className="register">
          <ModalHeader toggle={this.handlerTournamentEnrollmentCloseClick}>
            <button type="button" className="close" onClick={this.handlerTournamentEnrollmentCloseClick}>
              <img src="images/cross-icon-1.png" alt="image" />
            </button>
          </ModalHeader>
          <ModalBody className="py-3 px-5">
            {this.context.isExchangeTokenSet() && (
              <>
                <h5>Get started in just few seconds!</h5>
                <div className="form-area">
                  {!this.state.enrollmentSubmitting && !this.state.enrollmentMessage && (
                    <form action="#">
                      <div className="form-group d-flex">
                        <div className="checkbox_wrapper">
                          <input type="checkbox" onChange={this.onEnrollmentAgreeChange} checked={this.state.enrollmentAgree} />
                          {/* <label></label> */}
                        </div>
                        <span className="check_span">
                          I agree with{" "}
                          <Link to="/terms" target="_blank">
                            <span>user agreement</span>
                          </Link>
                          , and confirm that I am at least 18 years old!
                        </span>
                      </div>
                      <div className="form-group">
                        <button type="submit" className="cmn-btn" onClick={this.handlerTournamentEnrollmentSubmitClick} disabled={!this.state.enrollmentAgree}>
                          Enroll
                        </button>
                      </div>
                    </form>
                  )}

                  {this.state.enrollmentSubmitting && (
                    <div className="text-center">
                      <div className="spinner-border text-primary" />
                    </div>
                  )}

                  {this.state.enrollmentMessage && <div className="res_info">{this.state.enrollmentMessage}</div>}
                </div>
              </>
            )}

            {!this.context.isExchangeTokenSet() && <h5 className="">Please sign in to <a className="exchange-link" href="https://www.bonex.net">BoneX Margin</a><br />to continue!<LoginButton /></h5>
            }
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}
