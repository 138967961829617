import React from "react";
import { CurrentTournamentRankDto } from "../lib/drivers/dto/CurrentTournamentRankDto";
import { PortCentralServer } from "../lib/domain/Ports/PortCentralServer";
import { CurrentTournamentRankingRequestDto } from "../lib/drivers/dto/CurrentTournamentRankingRequestDto";
import Spinners from "../components/Spinners";
import { Layout } from "../layout";
import { LeaderboardRanking } from "../components/LeaderboardRanking";
import withRouterHook from "../withRouterHook";

type LeaderboardPageProps = {};

type Filter = {
  page: number;
  pageSize: number;
  total: number;
};

type LeaderboardPageState = {
  isLoading: boolean;
  list: CurrentTournamentRankDto[];

  // filter
  filter: Filter;
};

export class LeaderboardPage extends React.Component<LeaderboardPageProps, LeaderboardPageState> {
  state: LeaderboardPageState = {
    isLoading: true,
    list: null,

    // filter
    filter: {
      page: 1,
      pageSize: 10,
      total: 0
    }
  };

  // #region React Events
  componentDidMount = async () => {
    await this.fetchTournamentRanking();
  };

  componentDidUpdate = async (prevProps: LeaderboardPageProps, prevState: LeaderboardPageState) => {
    // execute
  };
  // #endregion

  // #region Functions
  fetchTournamentRanking = async () => {
    this.setLoading(true);

    const filter = this.state.filter;
    const offset = (filter.page - 1) * filter.pageSize;

    try {
      const response = await PortCentralServer.Repo.client.tournamentRanking({
        limit: filter.pageSize,
        offset: offset
      });

      if (response) {
        this.setState((prevState) => ({
          list: [...(prevState.list || []), ...response.data],
          isLoading: false
        }));
      }
    } catch (error) {
      this.setLoading(false);
    }
  };

  printPrize = (rank: number) => {
    let result = "-";

    switch (rank) {
      case 1:
        result = "$ 125,000";
        break;
      case 2:
        result = "$ 60,000";
        break;
      case 3:
        result = "$ 35,000";
        break;
      case 4:
        result = "$ 20,000";
        break;
      case 5:
        result = "$ 5,000";
        break;
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        result = "$ 1,000";
        break;
      default:
        break;
    }

    return result;
  };
  // #endregion

  // #region Handlers
  handleShowMoreClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    await this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        page: prevState.filter.page + 1
      }
    }));

    await this.fetchTournamentRanking();
  };
  // #endregion

  // #region State Setters
  setLoading = async (loading: boolean) => {
    await this.setState((prevState) => {
      return {
        isLoading: loading
      };
    });
  };
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <section id="leaderboard-prize">
            <div className="overlay pt-60 pb-60">
              {" "}
              <div className="container pt-120">
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-8 text-center">
                    <div className="section-header">
                      <h2 className="title">Leaderboard</h2>
                    </div>
                  </div>
                </div>
                {this.state.isLoading ? (
                  <Spinners setLoading={this.setLoading} />
                ) : (
                  <>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Prize</th>
                                <th className="text-start" scope="col">
                                  Ranking
                                </th>
                                <th scope="col">User</th>
                                <th scope="col">ROI</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(this.state.list || []).map((item: CurrentTournamentRankDto, key: number) => (
                                <tr key={key}>
                                  <td>{`${this.printPrize(item.rank)}`}</td>
                                  <th scope="row" className="rank">
                                    <LeaderboardRanking ranking={item.rank} />
                                  </th>
                                  <td>{item.nickName || item.userId}</td>
                                  <td>{item.points.toFixed(2)} %</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        {this.state.list && this.state.list.length < 100 && (
                          <div className="col-lg-12 d-flex justify-content-center">
                            <button
                              className="cmn-btn"
                              onClick={(e) => {
                                this.handleShowMoreClick(e);
                              }}
                            >
                              Show More
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </section>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(LeaderboardPage);
