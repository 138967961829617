
export enum CentralServerPathParamKind {
    contractId = "{contractId}"
}

export enum CentralServerQueryParamKind {
    // Unknown = "unknown",
}

export enum CentralServerEndpointKind {
    // tournament
    tournaments_current= "app/tournaments/current",
    tournaments_current_ranking= "app/tournaments/current/ranking",
    tournaments_current_my_information= "app/tournaments/current/my-information",
    tournaments_current_enroll= "app/tournaments/current/enroll"
}