import React from "react";
import { AppContext } from "../context/AppProvider";
import { Layout } from "../layout";
import withRouterHook from "../withRouterHook";

export type MyInformationPageProps = {};

type MyInformationPageState = {
  here: boolean;
};

class MyInformationPage extends React.Component<MyInformationPageProps, MyInformationPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: MyInformationPageState = {
    here: true
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    await this.setState((prevState) => {
      return { here: true };
    });
  };

  componentDidUpdate = async (prevProps: MyInformationPageProps, prevState: MyInformationPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="overlay pt-60 pb-60">
            <div className="container pt-120">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-8 text-center">
                  <div className="section-header">
                    <h2 className="title">My Information</h2>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">MyInformationPage</div>
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(MyInformationPage);
