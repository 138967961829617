export interface FaqItem {
    question: string;
    answer: string;
}

export const FaqData: FaqItem[] = [
    {
        question: "What is the BoneX Margin Trading Competition?",
        answer: "The BoneX Margin Trading Competition is a trading event where participants engage in futures contracts trading under specific rules. Participation implies agreement to these terms and conditions."
    }, {
        question: "How are scoring and rankings determined in the competition?",
        answer: "Rankings are based on Return on Investment (ROI) for each trade, with ROI data updated every 180 minutes. Final rankings are determined at the competition's conclusion."
    }, {
        question: "How do I enter the tournament?",
        answer: "Participants can join the tournament by clicking the 'Join Tournament' button on the platform."
    }, {
        question: "What contracts can I trade in the tournament?",
        answer: "Participants are allowed to trade any contract available on the platform."
    }, {
        question: "What are the minimum and maximum position limits?",
        answer: "The minimum position limit is set at 25 USDT, with no stated maximum limit."
    }, {
        question: "Is there a limit on the number of trades I can make?",
        answer: "There are no limits on the number of trades participants can execute."
    }, {
        question: "What is the volume requirement for the tournament?",
        answer: "The tournament does not specify any volume requirements for trading."
    }, {
        question: "Can I enroll in the tournament after it has started?",
        answer: "Yes, enrollment is possible even after the tournament has commenced."
    }, {
        question: "What is the leverage requirement for the tournament?",
        answer: "There is no requirement for minimum leverage to qualify for the tournament."
    }, {
        question: "What is the winning condition for the tournament?",
        answer: "Winners are determined based on the highest Return on Investment (ROI) achieved in their trades."

    }
];