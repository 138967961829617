import React from "react";
import { Layout } from "../layout";
import HomeCountdownNew from "../components/HomeCountdownNew";
import HomeCountdown from "../components/HomeCountdown";
import { HomeFaq } from "../components/HomeFaq";
import { HomeMyInformation, HomeEnterTournament } from "../components/HomeMyInformation";
import { HomeLeaderboard } from "../components/HomeLeaderboard";
import Spinners from "../components/Spinners";
import { GiveawayPopup } from "../components/GiveawayPopup";

export type HomePageProps = {};

type HomePageState = {};

export class HomePage extends React.Component<HomePageProps, HomePageState> {
  render() {
    return (
      <React.Fragment>
        <Layout>
          <section id="home-here" className="">
            <div className="hero-banner">
              <div className="container">
                <div className="row">
                  <div className="col-10 mx-auto">
                    <div className="bottom-area">
                      <div className="flex-container">
                        <div style={{ flex: "1 1 0%", textAlign: "center", maxWidth: "700px" }}>
                          <HomeCountdownNew targetDate="2024-05-07" />
                        </div>
                        <HomeEnterTournament />
                      </div>
                      <div className="bet-details d-flex justify-content-between">
                        <div className="left">
                          <h6>Min position</h6>
                          <h5>$ 25</h5>
                        </div>
                        <div className="mid">
                          <h6>Period</h6>
                          {/* <h6>Enrollment</h6> */}
                          <h5>
                            16.04.2024 <span>to</span> 06.05.2024
                          </h5>
                        </div>
                        <div className="right">
                          <h6>Type</h6>
                          <h5>ROI</h5>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="tournaments-prize">
            <div className="overlay pt-60 pb-60">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="section-header">
                      <h2 className="title">
                        Tournaments <span>Prize</span>
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="first-item text-center">
                      <img src="images/tournament/prize-trophy/prize-trophy-1.png" alt="image" />
                      <div className="info">
                        <h6>1st place</h6>
                        <h4>$ 125,000</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center mt-30">
                  <div className="col-lg-3 col-md-3 col-sm-3">
                    <div className="single-item text-center">
                      <img src="images/tournament/prize-trophy/prize-trophy-2.png" alt="image" />
                      <h6>2nd place</h6>
                      <h4>$ 60,000</h4>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3">
                    <div className="single-item text-center">
                      <img src="images/tournament/prize-trophy/prize-trophy-3.png" alt="image" />
                      <h6>3rd place</h6>
                      <h4>$ 35,000</h4>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-3">
                    <div className="single-item text-center">
                      <img src="images/tournament/prize-trophy/prize-trophy-4.png" alt="image" />
                      <h6>4th place</h6>
                      <h4>$ 20,000</h4>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3">
                    <div className="single-item text-center">
                      <img src="images/tournament/prize-trophy/prize-trophy-5.png" alt="image" />
                      <h6>5th place</h6>
                      <h4>$ 5,000</h4>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="price-rest">
                      <h4>6th - 10th</h4>
                      <h6> place get additional $ 1,000</h6>
                      <p className="small">Ranking is determined by ROI. There is not trading volume requirements to be eligible to enter leaderboard</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <HomeMyInformation />

          <HomeLeaderboard />

          <GiveawayPopup />
        </Layout>
      </React.Fragment>
    );
  }
}
