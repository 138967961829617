import React from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// style
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/style.css";

// components
import { ProtectedRoute } from "./components/ProtectedRoute";

// Pages
import { HomePage } from "./pages/HomePage";
import FaqPage from "./pages/FaqPage";
import LeaderboardPage from "./pages/LeaderboardPage";
import MyInformationPage from "./pages/MyInformationPage";
import RulesPage from "./pages/RulesPage";
import TermsPage from "./pages/TermsPage";

export class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="leaderboard" element={<LeaderboardPage />} />
          <Route path="rules" element={<RulesPage />} />
          <Route path="faq" element={<FaqPage />} />
          <Route path="terms" element={<TermsPage />} />
          <Route path="my-information" element={<MyInformationPage />} />
        </Routes>
        <ToastContainer />
      </React.Fragment>
    );
  }
}
