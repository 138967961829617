import React from "react";
import { CurrentTournamentRankDto } from "../lib/drivers/dto/CurrentTournamentRankDto";
import { PortCentralServer } from "../lib/domain/Ports/PortCentralServer";
import { CurrentTournamentRankingRequestDto } from "../lib/drivers/dto/CurrentTournamentRankingRequestDto";
import Spinners from "./Spinners";

type HomeLeaderboardProps = {};

type HomeLeaderboardState = {
  isLoading: boolean;
  list: CurrentTournamentRankDto[];
};

export class HomeLeaderboard extends React.Component<HomeLeaderboardProps, HomeLeaderboardState> {
  state: HomeLeaderboardState = {
    isLoading: true,
    list: null
  };

  componentDidMount = async () => {
    try {
      const filter: CurrentTournamentRankingRequestDto = {
        limit: 10,
        offset: 0
      };

      const response = await PortCentralServer.Repo.client.tournamentRanking(filter);

      if (response) {
        await this.setState((prevState) => {
          return {
            list: response.data,
            isLoading: false
          };
        });
      }
    } catch (error) {
      await this.setState((prevState) => {
        return {
          isLoading: false
        };
      });
    }
  };

  componentDidUpdate = async (prevProps: HomeLeaderboardProps, prevState: HomeLeaderboardState) => {
    // execute
  };

  printPrize = (rank: number) => {
    let result = "-";

    switch (rank) {
      case 1:
        result = "$ 125,000";
        break;
      case 2:
        result = "$ 60,000";
        break;
      case 3:
        result = "$ 35,000";
        break;
      case 4:
        result = "$ 20,000";
        break;
      case 5:
        result = "$ 5,000";
        break;
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        result = "$ 1,000";
        break;
      default:
        break;
    }

    return result;
  };

  // #region State Setters
  setLoading = async (loading: boolean) => {
    await this.setState((prevState) => {
      return {
        isLoading: loading
      };
    });
  };
  // #endregion

  render() {
    return (
      <React.Fragment>
        <section id="leaderboard-prize">
          <div className="container pt-60 pb-60">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8 text-center">
                <div className="">
                  <h2 className="title vector-title">Leaderboard</h2>
                </div>
              </div>
            </div>
            {this.state.isLoading ? (
              <Spinners setLoading={this.setLoading} />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Prize</th>
                            <th className="text-start" scope="col">
                              Ranking
                            </th>
                            <th scope="col">User</th>
                            <th scope="col">ROI</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.list || []).map((item: CurrentTournamentRankDto, key: number) => (
                            <tr key={key}>
                              <td>{`${this.printPrize(item.rank)}`}</td>
                              <th scope="row" className="rank">
                                <span className={`rank rank-${item.rank}`}>{item.rank}</span>
                              </th>
                              <td>{item.nickName || item.userId}</td>
                              <td>{item.points.toFixed(2)} %</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      </React.Fragment>
    );
  }
}
