import React, { Component } from 'react';

type LeaderboardRankingProps = {
  ranking: number;
};

type LeaderboardRankingState = {};

export class LeaderboardRanking extends Component<LeaderboardRankingProps, LeaderboardRankingState> {
  render() {
    const { ranking } = this.props;
    const rankImageMap: { [key: number]: string } = {
      1: 'rank_1.png',
      2: 'rank_2.png',
      3: 'rank_3.png',
      4: 'rank_4.png',
    };

    const imgSrc = rankImageMap[ranking]
      ? `images/tournament/leader-board/${rankImageMap[ranking]}`
      : null;

    return (
      imgSrc ? (
        <img src={imgSrc} alt={`Rank ${ranking}`} />
      ) : (
        <span>{ranking}</span>
      )
    );
  }
}
