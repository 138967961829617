import React, { useEffect, FC } from "react";

interface SpinnersProps {
  setLoading: (loading: boolean) => void;
}

const Spinners: FC<SpinnersProps> = ({ setLoading }) => {
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);

  //   return () => clearTimeout(timer);
  // }, [setLoading]);

  return (
    <React.Fragment>
      <div className="text-center">
        <div className="spinner-border text-primary position-absolute top-50 start-50 " />
      </div>
    </React.Fragment>
  );
};

export default Spinners;
