import React from "react";

// Props & State
export type FooterProps = {};

type FooterState = {};

export class Footer extends React.Component<FooterProps, FooterState> {
  render() {
    return (
      <React.Fragment>
        <footer id="footer-section">
          <div className="overlay">
            <div className="container">
              <div className="bottom-area pt-60">
                <div className="row d-flex header-area">
                  <div className="col-lg-8 col-md-8 d-flex justify-content-md-between justify-content-center align-items-center">
                    <div className="logo-section">
                      <a className="site-logo site-title" href="index.html">
                        <img src="images/bnx/logo-white.png" alt="site-logo" />
                      </a>
                    </div>
                    <ul className="navbar-nav main-menu d-flex align-items-center">
                      <li>
                        <a href="/leaderboard">Leaderboard</a>
                      </li>
                      {/* <li>
                        <a href="/rules">Rules</a>
                      </li> */}
                      <li>
                        <a href="/faq">FAQ</a>
                      </li>
                      <li>
                        <a href="/terms">Terms</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-4 col-md-4 d-flex align-items-center justify-content-center justify-content-md-end">
                    <div className="social-area d-flex">
                      <a href="https://twitter.com/BonexExchange" target="_blank">
                        <img src="images/social/twitter.png" alt="twitter" />
                      </a>
                      <a href="https://instagram.com/bonexmargin" target="_blank">
                        <img src="images/social/instagram.png" title="instagram" />
                      </a>
                      <a href="https://discord.gg/bonex" target="_blank">
                        <img src="images/social/discord.png" title="discord" />
                      </a>
                      <a href="https://t.me/BoneX_Official" target="_blank">
                        <img src="images/social/telegram.png" alt="telegram" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="main-content">
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-12">
                    <div className="left-area">
                      <p>{new Date().getFullYear()} &copy; <a href="https://www.bonex.net">BoneX Margin</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
