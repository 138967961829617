import React from "react";
import { Link } from "react-router-dom";

// Reactstrap

// Import menuDropdown
import { AppContext } from "../context/AppProvider";

// Props & State
export interface HeaderProps {
  toggleMenuCallback(): void;
}

type HeaderState = {
  megaMenu: boolean;
  isNavExpanded: boolean;
};

export class Header extends React.Component<HeaderProps, HeaderState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state = {
    megaMenu: false,
    isNavExpanded: false
  };

  // #region State Setters
  // #endregion

  // #region handlers
  toggleNav = () => {
    this.setState(prevState => ({
      isNavExpanded: !prevState.isNavExpanded
    }));
  };
  // #endregion

  render() {
    return (
      <React.Fragment>
      <header id="header-section">
        <div className="overlay">
          <div className="container">
            <div className="row d-flex header-area">
              <div className="logo-section flex-grow-1 d-flex align-items-center">
                <Link to="/" className="site-logo site-title">
                  <img src="images/bnx/logo-color.svg" alt="site-logo" />
                </Link>
              </div>
              <button
                className={`navbar-toggler ml-auto ${this.state.isNavExpanded ? '' : 'collapsed'}`}
                type="button"
                onClick={this.toggleNav}
                aria-expanded={this.state.isNavExpanded}
              >
                <i className="fas fa-bars"></i>
              </button>
              <nav className="navbar navbar-expand-lg p-0">
                <div className={`navbar-collapse collapse ${this.state.isNavExpanded ? 'show' : ''}`} id="navbarSupportedContent">
                  <ul className="navbar-nav main-menu ml-auto">
                    <li>
                      <Link to="https://www.bonex.net">BoneX Margin</Link>
                    </li>
                    <li>
                      <Link to="/leaderboard">Leaderboard</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/terms">Terms</Link>
                    </li>
                  </ul>
                  <div className="right-area header-action d-flex align-items-center">
                    {/* Additional elements here */}
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
    );
  }
}
