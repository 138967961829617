import React from 'react';

class LoginButton extends React.Component {
  handleClick = () => {
    window.location.href = "https://www.bonex.net/en_US/login";
  };

  render() {
    return (
      <button type="button" className="cmn-btn TournamentEnrollment btnLogin" onClick={this.handleClick}>
        Sign in
      </button>
    );
  }
}

export default LoginButton;
