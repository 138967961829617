import React from "react";
import { Col, Row } from "reactstrap";

interface HomeCountdownNewProps {
  targetDate: string;
}

interface HomeCountdownNewState {
  timeLeft: {
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
  };
}

class HomeCountdownNew extends React.Component<HomeCountdownNewProps, HomeCountdownNewState> {
  timerID: NodeJS.Timeout | undefined;

  constructor(props: HomeCountdownNewProps) {
    super(props);
    this.state = {
      timeLeft: this.calculateTimeLeft()
    };
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    if (this.timerID) {
      clearInterval(this.timerID);
    }
  }

  calculateTimeLeft() {
    const { targetDate } = this.props;
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = { days: "00", hours: "00", minutes: "00", seconds: "00" };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24))
          .toString()
          .padStart(2, "0"),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24)
          .toString()
          .padStart(2, "0"),
        minutes: Math.floor((difference / 1000 / 60) % 60)
          .toString()
          .padStart(2, "0"),
        seconds: Math.floor((difference / 1000) % 60)
          .toString()
          .padStart(2, "0")
      };
    }

    return timeLeft;
  }

  tick() {
    this.setState({
      timeLeft: this.calculateTimeLeft()
    });
  }

  render() {
    const { days, hours, minutes, seconds } = this.state.timeLeft;

    return (
      <div className="draw-counter-new">
        {/* <Row className="countdown">
          <Col>Starts in</Col>
          <Col>{days} D</Col>
          <Col>{hours} H</Col>
          <Col>{minutes} M</Col>
          <Col>{seconds} S</Col>
          <Col>
            <a href="https://www.bonex.net" target="_blank">
              START TRADING
            </a>
          </Col>
        </Row> */}
        <div className="countdown">
          <div className="ttl">Ends&nbsp;in</div>
          <div className="counter">
            <div className="time-unit">
              <div id="days" className="number">
                {days}
              </div>
              <div className="label">D</div>
            </div>
            <div className="time-unit">
              <div id="hours" className="number">
                {hours}
              </div>
              <div className="label">H</div>
            </div>
            <div className="time-unit">
              <div id="minutes" className="number">
                {minutes}
              </div>
              <div className="label">M</div>
            </div>
            <div className="time-unit">
              <div id="seconds" className="number">
                {seconds}
              </div>
              <div className="label">S</div>
            </div>
          </div> 
        </div>
      </div>
    );
  }
}

export default HomeCountdownNew;
