import React from "react";
import { AppContext } from "../context/AppProvider";
import { Layout } from "../layout";
import withRouterHook from "../withRouterHook";
import { FaqData, FaqItem } from "../lib/data/faq";

export type FaqPageProps = {};

type FaqPageState = {
};

class FaqPage extends React.Component<FaqPageProps, FaqPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: FaqPageState = {
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
    // await this.setState((prevState) => {
    //   return { here: true };
    // });
  };

  componentDidUpdate = async (prevProps: FaqPageProps, prevState: FaqPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <section id="faq-section" className="faq">
            <div className="overlay pt-60 pb-60">
              <div className="container pt-120">
                <div className="row d-flex justify-content-center"> 
                  <div className="col-lg-8">
                    <div className="section-header text-center">
                      <h2 className="title">Frequently asked questions</h2>
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-12">
                    <div className="tab-pane fade show active" id="account" role="tabpanel" aria-labelledby="account-tab">
                      <div id="accordion-one">
                        {FaqData.map((item: FaqItem, key: number) => (
                          <div className="card" key={key}>
                            <div className="card-header" id={`heading${key}`}>
                              <h6 className="mb-0">
                                <button className="btn btn-link" data-toggle="collapse" data-target={`#collapse${key}`} aria-expanded="true" aria-controls={`collapse${key}`}>
                                  {item.question}
                                </button>
                              </h6>
                            </div>
                            <div id={`collapse${key}`} className="collapse show" aria-labelledby={`heading${key}`} data-parent={`#accordion-${key}`}>
                              <div className="card-body">
                                <p>{item.answer}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(FaqPage);
