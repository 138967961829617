import React from "react";
import { AppContext } from "../context/AppProvider";
import { Layout } from "../layout";
import withRouterHook from "../withRouterHook";

export type TermsPageProps = {};

type TermsPageState = {
  here: boolean;
};

class TermsPage extends React.Component<TermsPageProps, TermsPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: TermsPageState = {
    here: true
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    await this.setState((prevState) => {
      return { here: true };
    });
  };

  componentDidUpdate = async (prevProps: TermsPageProps, prevState: TermsPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="overlay pt-60 pb-60">
            <div className="container pt-120">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-8 text-center">
                  <div className="section-header">
                    <h2 className="title">Terms and Conditions</h2>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 text-content">
                  <h3>1. Introduction</h3>
                  <p>
                    Welcome to the BoneX Margin Trading Competition. This document sets forth the terms and conditions governing your participation in the Trading Competition. By
                    participating, you agree to these Terms and Conditions in their entirety.
                  </p>

                  <h3>2. Giveaway</h3>
                  <p>
                    <strong>Eligibility</strong>: Open to all registered users of BoneX Margin, except for the top 10 winners of the recent tournament and employees of BoneX
                    Margin.
                  </p>

                  <p>
                    How to Enter: Participants must submit their Platform UID and a screenshot of their account on bonex.net. Entrants are also required to repost a specific Tweet
                    about the giveaway and provide a screenshot of this Tweet Repost along with their Account ID.
                  </p>

                  <p>
                    <strong>Winner Selection</strong>: One winner will be randomly selected from eligible entries after the tournament concludes.
                  </p>

                  <p>
                    <strong>Prize</strong>: The winner will receive 100 Solana (SOL), credited directly to their BoneX Margin account.
                  </p>

                  <p>
                    <strong>Winner Notification</strong>: The winner will be notified via email by BoneX Margin support team.
                  </p>

                  <p>
                    <strong>Prize Distribution</strong>: BoneX Margin will top up the winner's account with 100 Solana following the successful verification of the winner's
                    eligibility and compliance with the giveaway rules.
                  </p>

                  <p>
                    <strong>Agreement to Terms</strong>: By participating in this giveaway, entrants agree to these terms and conditions.
                  </p>

                  <p>
                    <strong>Privacy Policy</strong>: Participation signifies consent to BoneX Margin's collection, use, and disclosure of personal information as outlined in its
                    privacy policy.
                  </p>

                  <h3>3. Eligibility and Participation</h3>
                  <p>
                    <strong>Minimum Starting Capital</strong>: There is not limit for minimum starting capital.
                  </p>
                  <p>
                    <strong>Deposits</strong>: Unlimited deposits are permitted throughout the competition.
                  </p>
                  <p>
                    <strong>Leverage</strong>: No leverage requirement.
                  </p>
                  <p>
                    <strong>Position Size</strong>: The minimum order value before leverage is set at 25 USDT.
                  </p>
                  <p>
                    <strong>Trading Activity</strong>: Trades can be made on all Future Contracts available on BoneX Margin.
                  </p>
                  <h3>4. Scoring and Rankings</h3>
                  <p>
                    The primary metric for ranking participants will be the Return on Investment (ROI) for each position. Participants' points will be calculated as the sum of the
                    ROIs from all their positions. The ROI data will be updated and displayed on the competition page every 180 minutes.
                  </p>
                  <p>
                    The final ranking of participants will be conclusively determined at the end of the round. It's important to note that all tournament data displayed during the
                    active competition period is provisional and intended solely for reference purposes.
                  </p>
                  <h3>5. Prizes</h3>
                  <p>The prize pool will be distributed as follows:</p>
                  <p>
                    <strong>1st Place:</strong> $ 125,000
                  </p>
                  <p>
                    <strong>2nd Place:</strong> $ 60,000
                  </p>
                  <p>
                    <strong>3rd Place:</strong> $ 35,000
                  </p>
                  <p>
                    <strong>4th Place:</strong> $ 20,000
                  </p>
                  <p>
                    <strong>5th Place:</strong> $ 5,000
                  </p>
                  <p>
                    <strong>6th to 10th Places:</strong> Each will receive $ 1,000.
                  </p>
                  <h3>6. General Rules</h3>
                  <p>BoneX Margin reserves the right to the final interpretation of this event. For inquiries, please contact our customer support.</p>
                  <p>
                    All participants must adhere to the BoneX Margin Terms of Service. BoneX Margin reserves the right to disqualify participants for dishonest or abusive
                    activities, including but not limited to bulk-account registrations and activities associated with unlawful or harmful purposes.
                  </p>
                  <p>
                    In cases where our risk detection engine detects users with similar trading strategies, only the participant with the highest P&amp;L (%) or Trading Volume will
                    be allowed to continue in the competition.
                  </p>
                  <h3>7. Disclaimers and Limitations</h3>
                  <p>BoneX Margin is not responsible for any losses incurred during the competition.</p>
                  <p>Participants are expected to trade responsibly and at their own risk.</p>
                  <p>BoneX Margin reserves the right to modify terms and conditions, including prize distribution, at any time without prior notice.</p>
                  <h3>8. Agreement</h3>
                  <p>
                    <strong>By participating in this competition, you acknowledge that you have read and agreed to these terms and conditions.</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(TermsPage);
