import axios, { AxiosRequestConfig } from 'axios';
import { ServerEndpointBuilder } from "../core/ServerDriver/ServerEndpointBuilder";
import { AuthCookieManager } from '../AuthCookieManager';
import { CentralServerEndpointKind } from './CentralServerClientEnums';
import { CurrentTournamentRankingRequestDto } from './dto/CurrentTournamentRankingRequestDto';
import { CurrentTournamentRankingResponseDto } from './dto/CurrentTournamentRankingResponseDto';
import { CurrentTournamentEnrollmentResponseDto } from './dto/CurrentTournamentEnrollmentResponseDto';
import { CurrentTournamentUserInformationDto } from './dto/CurrentTournamentUserInformationDto';

type CentralErrorHandler = (error: unknown) => void;

export class CentralServerClient {
    // #region Private fields
    private readonly _endpointBuilder: ServerEndpointBuilder;
    // private _accessToken: string;
    // #endregion

    // #region Properties
    public get endpointBuilder(): ServerEndpointBuilder {
        return this._endpointBuilder;
    }

    // public get accessToken(): string {
    //     return this._accessToken;
    // }

    // public set accessToken(value: string) {
    //     this._accessToken = value;
    // }

    errorHandler?: CentralErrorHandler;
    // #endregion

    // #region Constructor
    constructor() {
        this._endpointBuilder = new ServerEndpointBuilder(process.env.REACT_APP_CENTRAL_BASE_URL);
    }
    // #endregion

    // #region Tournament Endpoints
    async tournamentMyInformation(): Promise<CurrentTournamentUserInformationDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.tournaments_current_my_information.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: CurrentTournamentUserInformationDto = data as CurrentTournamentUserInformationDto;

            return result;
        } catch (error) {
            throw error;
        }
    }

    async tournamentRanking(model: CurrentTournamentRankingRequestDto): Promise<CurrentTournamentRankingResponseDto> {
        try {

            // TEST
            // const allData: CurrentTournamentRankingResponseDto = {
            //     total: 10,
            //     data: []
            // };
            // allData.data.push({ rank: 1, userId: "44****2", nickName: "Blaze42", points: 32.50, tradingVolume: 1000 });
            // allData.data.push({ rank: 2, userId: "43****2", nickName: "Stormy7", points: 21.00, tradingVolume: 1000 });
            // allData.data.push({ rank: 3, userId: "31****6", nickName: "SunRay89", points: 16.44, tradingVolume: 1000 });
            // allData.data.push({ rank: 4, userId: "22****7", nickName: "IceBear3", points: 12.13, tradingVolume: 1000 });
            // allData.data.push({ rank: 5, userId: "33****3", nickName: "NightOwl5", points: 8.99, tradingVolume: 1000 });
            // allData.data.push({ rank: 6, userId: "42****2", nickName: "GoldStar9", points: 8.21, tradingVolume: 1000 });
            // allData.data.push({ rank: 7, userId: "32****4", nickName: "SkyDiver2", points: 8.09, tradingVolume: 1000 });
            // allData.data.push({ rank: 8, userId: "31****4", nickName: "FireFly77", points: 7.23, tradingVolume: 1000 });
            // allData.data.push({ rank: 9, userId: "32****6", nickName: "WolfPack4", points: 5.56, tradingVolume: 1000 });
            // allData.data.push({ rank: 10, userId: "32****3", nickName: "EagleEye1", points: 5.21, tradingVolume: 1000 });

            // return allData;
            // TEST

            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.tournaments_current_ranking.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: CurrentTournamentRankingResponseDto = data as CurrentTournamentRankingResponseDto;

            return result;
        } catch (error) {
            throw error;
        }
    }

    async tournamentEnroll(): Promise<CurrentTournamentEnrollmentResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.tournaments_current_enroll.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, null, headers);
            const result: CurrentTournamentEnrollmentResponseDto = data as CurrentTournamentEnrollmentResponseDto;

            return result;
        } catch (error) {
            throw error;
        }
    }
    // #endregion

    // #region Private Functions
    private getHeaders(): AxiosRequestConfig {
        const token = AuthCookieManager.getExchangeToken();

        const headers: AxiosRequestConfig = {};

        if (token) {
            headers.headers = {
                "x-exchange-token": `${token}`
            };
        }

        return headers;
    }
    // #endregion
}