import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { AppContext } from "../context/AppProvider";

export type GiveawayPopupProps = {};

type GiveawayPopupState = {
  modalOpen: boolean;

  isLoading: boolean;
};

export class GiveawayPopup extends React.Component<GiveawayPopupProps, GiveawayPopupState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: GiveawayPopupState = {
    modalOpen: true,

    isLoading: true
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: GiveawayPopupProps, prevState: GiveawayPopupState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  handlerCloseClick = async (e: React.FormEvent<HTMLButtonElement>) => {
    await this.setState((prevState) => {
      return { modalOpen: false };
    });
  };
  // #endregion

  // #region State Setters
  setLoading = async (loading: boolean) => {
    await this.setState((prevState) => {
      return {
        isLoading: loading
      };
    });
  };
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.handlerCloseClick}
          centered={true}
          unmountOnClose={true}
          className="modal-giveaway"
          style={{ maxWidth: "700px", width: "100%" }}
        >
          <ModalHeader toggle={this.handlerCloseClick}>
            <button type="button" className="close" onClick={this.handlerCloseClick}>
              <img src="images/btnClose.webp"  className="btnClose" alt="image" title="giveaway" />
            </button>
          </ModalHeader>
          <ModalBody className="py-3 px-3">
            <a href="https://x.com/bonexexchange/status/1775883522655256745?s=46" target="_blank" title="giveaway">
              <img src="images/banners/giveaway-banner.jpg" alt="giveaway" />
            </a>
            {/* <h5>Win 100 SOL in BoneX Margin's Giveaway!</h5>
            <h6>🚀 Open to All Users! (Top 10 and employees excluded)</h6>
            <h6>🌐 Enter Easily: Share your account screenshot & repost our tweet!</h6>
            <h6>🏆 Win 100 SOL: Could you be the lucky one?</h6>
            <h6>🎉 Get Ready! Winner announced after the tournament.</h6>
            <br />
            <h6>👉 Join Now & Embark on Your SOL Journey!</h6> */}
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}
